body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mt64 {
    margin-top: 64px !important;
}

.mt24 {
    margin-top: 24px !important;
}

.mt8 {
    margin-top: 8px !important;
}

.mt16 {
    margin-top: 16px !important;
}

.mt32 {
    margin-top: 32px !important;
}

.font30 {
    font-size: 30px;
}

.font20 {
    font-size: 20px;
}

.font-12rem {
    font-size: 1.2rem;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.d-flex {
    display: flex;
}

.flex-right {
    display: flex;
    justify-content: flex-end;
}

.font-bold {
    font-weight: bold !important;
}

.font-weight500 {
     font-weight: 500;
 }

.text-left {
    text-align: left;
}

.display-block {
    display: block;
}

.display-none {
    display: none !important;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.color-black {
    color: #000000 !important;
}

.gray-primary {
    color: #a6a6a6;
}

.inputRounded .MuiOutlinedInput-root{
    border-radius: 50px;
}

.buy-token-list-name {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    color: #1A1A1A;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.buy-token-list-symbol {
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    margin: 5px 0 0 0;
}

.buy-token-list-symbol-color {
    color: #C7C7C7;
}

.buy-token-list-symbol-inc {
    color: #00B171;
}

.buy-token-list-symbol-dec {
    color: #F44336;
}

.buy-amount {
    color: #16359DB2;
    font-size: 18px;
}

.navigation-modal .MuiPaper-root {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    min-height: 100%;
}

.header-notice-bar {
    background: linear-gradient(89.98deg, rgba(53, 224, 244, 0.1) 0.02%, rgba(51, 215, 240, 0.1) 6.97%, rgba(46, 189, 228, 0.1) 18.55%, rgba(37, 148, 208, 0.1) 33.31%, rgba(25, 91, 182, 0.1) 50.5%, rgba(17, 50, 163, 0.1) 61.64%, rgba(17, 50, 163, 0.1) 99.98%);
    color: black;
    padding: 10px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 14px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.back-btn-text {
    color: #16359D !important;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px !important;
}

.menu-heading {
    margin: 70px 0 60px 0 !important;
    color: #16359D;
    font-weight: bold !important;
    text-align: center;
}

.menu-buttons-refresh {
    position: absolute !important;
    right: 8px;
    color: #16359D !important;
}

.menu-buttons {
    position: absolute !important;
    right: 8px;
    top: 8px;
    color: #16359D !important;
}

.back-button {
    position: absolute !important;
    left: 8px;
    color: #16359D !important;
    z-index: 10;
}

.navigation-back-text {
    position: absolute !important;
    left: 55px;
    color: #16359D !important;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 350px;
}

.menu-item {
    padding: 13px 30px;
    margin: 19px 28px;
    background: #FBFBFB;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0; 
    color: #16359D;
}

.menu-item:hover {
    color: white;
    background: linear-gradient(89.98deg, #35E0F4 0.02%, #33D7F0 6.97%, #2EBDE4 18.55%, #2594D0 33.31%, #195BB6 50.5%, #1132A3 61.64%, #1132A3 99.98%);
}

.menu-item-name {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menu-item-image {
    max-width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
}

.buy-select {
    padding: 20px;
    background: #FBFBFB;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    cursor: pointer;
}

.menu-logout-text {
    color: #A1A1A1;
    font-size: 14px !important;
    font-weight: 700 !important;
}

.content-center {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
}

.items-center {
    align-items: center;
}

.login-wrapper {
    min-height: 100vh;
    background: linear-gradient(180deg, #35E0F4 0.45%, #33D7F0 7.35%, #2EBDE4 18.82%, #2594D0 33.45%, #195BB6 50.5%, #1132A3 61.53%, #1132A3 99.55%);
}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: calc(100vh - 68px);
}

.color-white {
    color: white;
}

.login-buttons {
    padding: 16px 10px 16px 10px !important;
    background: transparent !important;
    border-radius: 100px !important;
}

.login-buttons.sign-up {
    margin-top: 64px !important;
}

.login-buttons.sign-in {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
}

.white-border {
    border: 3px solid #FFFFFF !important;
}

.navigation-login-border {
    border: 3px solid #16359D !important;
}

.navigation-login-background {
    background: linear-gradient(89.98deg, #35E0F4 0.02%, #33D7F0 6.97%, #2EBDE4 18.55%, #2594D0 33.31%, #195BB6 50.5%, #1132A3 61.64%, #1132A3 99.98%) !important;
}

.navigation-login-buttons {
    color: #16359D;
}

.sign-up-label {
    position: relative;
    top: 3px;
}

.sign-in-logo {
    margin-top: 30px;
}

.sign-in-logo-identity {
    margin-top: 50px;
}

.fiat-token-swap {
    margin-left: 17px;
}

.token-amount {
    color: #16359D;
    font-size: 30px;
    font-weight: bold;
    position: relative;
    bottom: 10px;
}

.estimate-amount {
    font-size: 15px !important;
    font-weight: bold !important;
    color: #A1A1A1;
}

.network-desc {
    font-size: 13px !important;
    color: #C7C7C7;
}

.network-indicator {
    color: white;
    border-radius: 5px;
    margin-top: 16px !important;
}

.eth-network {
    background: linear-gradient(180deg, #627EEA 0%, #98AEFF 100%);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

.polygon-network {
    background: linear-gradient(180deg, #8347e5 0%, #9f7ae0 100%);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

.ava-network {
    background: linear-gradient(360deg, #E84142 0%, #B70001 100%);
}

.bsc-network {
    background: linear-gradient(180deg, #ffb915 0%, #ffcc18 100%);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

.network-img {
    padding-left: 16px !important;
    padding-top: 10px !important;
}

.network-name {
    position: relative;
    top: 3px;
    padding: 0 !important;
}

.sdk-btn {
    padding: 16px 10px 16px 10px !important;
    background: linear-gradient(89.98deg, #35E0F4 0.02%, #33D7F0 6.97%, #2EBDE4 18.55%, #2594D0 33.31%, #195BB6 50.5%, #1132A3 61.64%, #1132A3 99.98%) !important;
    border-radius: 100px !important;;
}

.sdk-theme-btn {
    padding: 16px 10px 16px 10px !important;
    border-radius: 100px !important;;
}

.sdk-theme-btn:disabled, .sdk-btn:disabled, .post-twitter-btn:disabled {
    background: #E4E4E4 !important;
    color: #C7C7C7 !important;
}

.sdk-btn2, .sdk-btn-connect-wallet {
    padding: 10px 12px !important;
    border-radius: 100px !important;
    background: white !important;
    border: 3px solid #000000 !important;
    color: #000000 !important;
    font-size: 14px !important;
    text-transform: none !important;
    box-shadow: none !important;
}

.sdk-btn2:disabled, .sdk-btn-connect-wallet:disabled {
    border: 3px solid #A6A6A6 !important;
    color: #A6A6A6 !important;
}

.sdk-btn2:hover {
    padding: 13px 15px !important;
    border: none !important;
    color: white !important;
    background: linear-gradient(89.98deg, #35E0F4 0.02%, #33D7F0 6.97%, #2EBDE4 18.55%, #2594D0 33.31%, #195BB6 50.5%, #1132A3 61.64%, #1132A3 99.98%) !important;
}

.sdk-btn-connect-wallet:hover {
    border: 3px solid #000000 !important;
    background: #000000 !important;
    color: white !important;
}

.sdk-btn3 {
    padding: 13px 15px !important;
    border-radius: 100px !important;
    background: linear-gradient(89.98deg, #35E0F4 0.02%, #33D7F0 6.97%, #2EBDE4 18.55%, #2594D0 33.31%, #195BB6 50.5%, #1132A3 61.64%, #1132A3 99.98%) !important;
    color: white !important;
    font-size: 14px !important;
    text-transform: none !important;
    box-shadow: none !important;
}

.sdk-btn3:hover {
    padding: 10px 12px !important;
    background: black !important;
    border: 3px solid #000000 !important;;
    color: white !important;
}

.wallet-select-dd {
    background: #F0F0F0 !important;
    font-size: 12px !important;
    padding: 0px !important;
    border-radius: 100px !important;
}

.wallet-select-dd .MuiSelect-select {
    padding: 20px !important;
}

.wallet-select-dd:before {
    border-bottom: none !important;
}

.asset-header {
    display: flex;
    height: 68px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0; 
    background: linear-gradient(89.98deg, rgba(53, 224, 244, 0.1) 0.02%, rgba(51, 215, 240, 0.1) 6.97%, rgba(46, 189, 228, 0.1) 18.55%, rgba(37, 148, 208, 0.1) 33.31%, rgba(25, 91, 182, 0.1) 50.5%, rgba(17, 50, 163, 0.1) 61.64%, rgba(17, 50, 163, 0.1) 99.98%);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
}

.asset-close-btn {
    position: absolute !important;
    right: 8px;
    top: 12px;
    color: #000000 !important;
}

.document-item {
    padding: 20px;
    height: 80px;
    background: #FBFBFB;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    border-radius: 7px;
    cursor: pointer;
}

.video-box {
    /*border: 1px solid #ebebeb;*/
    position: relative;
}

.video-box::after {
    content: '';
    background: url(./images/overlay-picture.png) no-repeat center center/cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.video-box-inner-content {
    max-width: 100%;
    height: auto;
}

.pin-img {
    margin-left: 20px;
}

.confirm-dialog {
    background: linear-gradient(180deg, #FFFFFF -34.21%, #44DCB7 342.37%);
}

.confirm-dialog-title {
    color: #16359D;
    font-weight: bold !important;
    font-size: 28px !important;
}

.normal-text {
    text-transform: inherit !important;
}

.transform-none {
    text-transform: none !important;
}

.step-active {
    margin-left: 20px;
}

.login-buttons:disabled {
    background: #E4E4E4 !important;
    color: #C7C7C7 !important;
}

.doc-video-box {
    position:relative
}
.doc-video-box:before,
.doc-video-box:after,
.doc-video-box>:first-child:before,
.doc-video-box>:first-child:after {
    position:absolute;
    content:' ';
    width:80px;
    height: 80px;
    border: 4px solid#16359D;
}
.doc-video-box:before {
    top:-20px;
    left:-20px;
    border-width: 4px 0 0 4px;
    border-top-left-radius: 30px;
}
.doc-video-box:after {
    top:-20px;
    right:-20px;
    border-width: 4px 4px 0 0;
    border-top-right-radius: 30px;
}
.doc-video-box>:first-child:before {
    bottom:-15px;
    right:-20px;
    border-width: 0 4px 4px 0;
    border-bottom-right-radius: 30px;
}
.doc-video-box>:first-child:after {
    bottom:-15px;
    left:-20px;
    border-width: 0 0 4px 4px;
    border-bottom-left-radius: 30px;
}

.image-corner-color:before,
.image-corner-color:after,
.image-corner-color>:first-child:before,
.image-corner-color>:first-child:after {
    border-color: #00B171 !important;
}

.contact-link {
    text-decoration: none;
    color: inherit;
}

.contact-item {
    padding: 20px;
    background: #FBFBFB;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    border-radius: 7px
}

.phone-caption {
    color: #C7C7C7 !important;
    font-weight: bold !important;
}

.text-field-helper-error {
    text-align: right !important;
    color: red !important;
    margin-bottom: -24px !important;
}

.total-amount {
    color: #16359D;
    font-size: 20px;
    font-weight: bold;
}

.confirm-buy-edit {
    background: linear-gradient(180deg, #FFFFFF -34.21%, #44DCB7 342.37%) !important;
    border-radius: 100px !important;
    color: black !important;
    font-weight: bold !important;
    padding: 10px 12px !important;
}

.confirm-buy-delete-disable {
    background: #16359D !important;
    border-radius: 100px !important;
    color: white !important;
    font-weight: bold !important;
    padding: 10px 12px !important;
}

.bank-account {
    position: relative;
    border: 2px solid #C7C7C7;
    border-radius: 5px;
    padding: 15px;
}

.bank-remove-icon {
    position: absolute !important;
    top: -20px !important;
    right: -20px !important;
    color: #F44336 !important;
}

.timer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 0px;
    background: #1A1A1A;
    color: white;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 700;
    width: 100px;
}

.bank-item {
    padding: 20px;
    margin: 25px 0px;
    /*height: 80px;*/
    background: #F8F8F8;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    cursor: pointer;
}

.terms-and-conditions {
    height: 500px;
    overflow: scroll;
    overflow-x: hidden;
}

.keyboard-btn {
    user-select: none;
    height: 90px;
    cursor: pointer;
}

.keyboard-btn h4 {
    color: #16359D;
}

.keyboard-btn span {
    color: #C7C7C7;
}

.keyboard-btn:active, .keyboard-btn.active {
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 2px rgba(22, 53, 157, 0.3);
}

.keyboard-btn:active span, .keyboard-btn.active span {
    font-weight: 500 !important;
    color: black;
}

.keyboard-btn:active h4, .keyboard-btn.active h4 {
    font-weight: 500 !important;
    color: #052180;
}

.confirm-transaction-wrapper {
    background-color: #F8F8F8;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    width: 100%;
}

.confirm-transaction-container {
    padding: 12px 25px;
}

.confirm-transaction-total {
    border-top: 1px solid #E4E4E4;
    padding: 12px 25px;
    background-color: white;
}

.st-button-submit {
    font-weight: bold;
    padding: 16px 10px 16px 10px;
    background: linear-gradient(89.98deg, #35E0F4 0.02%, #33D7F0 6.97%, #2EBDE4 18.55%, #2594D0 33.31%, #195BB6 50.5%, #1132A3 61.64%, #1132A3 99.98%);
    border-radius: 100px;
    width: 100%;
    border: 0;
    color: #ffffff;
    cursor: pointer;
}

.st-card-number-iframe, .st-expiration-date-iframe, .st-security-code-iframe {
    width: 100%;
}

.st-button-submit__disabled {
    background: #E4E4E4 !important;
    color: #C7C7C7 !important;
}

.full-width {
    width: 100%;
}

.buy-select-grid {
    position: relative;
    top: 5px;
}

.primary-color {
    color: #16359D;
}

.buy-amount-field {
    background: #FBFBFB;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.buy-amount-field-symbol, .buy-amount-field input {
    font-size: 20px !important;
    font-weight: bold !important;
    color: black !important;
}

.buy-modal-text-header {
    color: #16359D;
}

.buy-modal-item {
    padding: 16px 20px;
    cursor: pointer;
}

.buy-modal-item:hover {
    background: rgba(0, 0, 0, 0.06);
}

.buy-modal-content {
    padding: 0 !important;
    height: 600px;
}

.bank-modal-content {
    padding: 20 !important;
    height: 600px;
}

.sup-head {
    color: #16359D;
    font-weight: bold;
}

.sup-pa {
    margin: 5px 0px;
}

.dropdown-list-item {
    vertical-align: -5px;
    min-width: auto !important;
    margin-right: 10px;
}

.buy-select-dd {
    border-top-left-radius: 0px !important;
}

.buy-select-dd:before {
    border-bottom: none !important;
}

.buy-select-dd .MuiSelect-select {
    font-size: 21px !important;
    font-weight: bold !important;
    color: #16359D !important;
    padding: 26px 12px 17px 12px !important;
}

.buy-select-dd .dropdown-list-item, .buy-select-dd span {
    position: relative;
    bottom: 5px;
}

.buy-payment-radio-button {
    border: 1px solid #D9D9D9;
    padding: 15px;
    height: 95px;
    border-radius: 5px;
}

.buy-payment-radio-button.active {
    border: 1px solid #1976d2 !important;
}

.buy-payment-radio-button-label {
    white-space: nowrap;
}

.buy-wallet-address-field input {
    font-weight: bold !important;
}

.connect-wallet-btn {
    padding: 10px !important;
    font-size: 18px !important;
    text-transform: none !important;
    background-color: #8CD6BB !important;
}

.login-connect-wallet-btn {
    padding: 10px !important;
    font-size: 18px !important;
    text-transform: none !important;
    background-color: #7FC8E5 !important;
}

.connect-wallet-signup {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 12px !important;
    text-decoration: underline;
    color: #16359D;
    cursor: pointer;
}

.pin-term-text {
    cursor: pointer;
    text-decoration: underline;
}

.political-expose {
    background: #F8F8F8;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    padding: 40px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}

.political-expose.active {
    background: #16359D;
    color: white;
}

.country-select-field input {
    /*font-size: 20px !important;*/
    font-weight: bold !important;
    color: #16359D !important;
}

.fee-area span {
    font-size: 14px !important;
}

.info-fee-icon {
    margin-bottom: -15px !important;
    top: -9px;
}

.fee-detail-buy {
    font-size: 14px;
    font-weight: bold;
    color: #A6A6A6;
}

.pay-amount-field input {
    font-size: 23px !important; /*font-size: 20px !important;*/
    font-weight: bold !important;
    color: black !important;
}

.pay-amount-field .MuiFilledInput-root {
    border-top-right-radius: 0px !important;
}

.pay-amount-field .MuiFilledInput-root:before {
    border: none !important;
}

.crypto-field input {
    padding: 20px 12px 30px 12px !important;
}

.fiat-field input {
    padding: 30px 12px 20px 12px !important;
}

.estimate-amount-field input {
    font-size: 18px !important;
    font-weight: bold !important;
    color: black !important;
}

.fee-labels {
    color: #a6a6a6;
    font-size: 13px;
    margin-top: 3px !important;
}

.low-fee-label {
    font-size: 12px !important;
    font-weight: 500 !important;
}

.login-inform-text {
    font-weight: bold;
    color: #f94c69;
}

.order-detail-grid {
    padding: 10px;
}

.connect-wallet-wrapper hr {
    border-bottom-width: 2px;
}

.order-detail-info p {
    font-size: 14px !important;
}

.buy-sell-btn {
    position: absolute;
    top: 0px;
    height: 68px;
    color: #000000;
    background: linear-gradient(89.98deg, rgba(53, 224, 244, 0.1) 0.02%, rgba(51, 215, 240, 0.1) 6.97%, rgba(46, 189, 228, 0.1) 18.55%, rgba(37, 148, 208, 0.1) 33.31%, rgba(25, 91, 182, 0.1) 50.5%, rgba(17, 50, 163, 0.1) 61.64%, rgba(17, 50, 163, 0.1) 99.98%);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
}

.buy-sell-btn .active {
    color: #16359D !important;
    border-bottom: 4px solid #16359D;
    /* padding-bottom: 5px; */
}

.buy-sell-item {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.buy-sell-text {
    font-weight: 700 !important;
    font-size: 16px !important;
}

.order-summary-cw {
    color: rgba(0, 0, 0, 0.54);
}

.identity-home-wrapper {
    min-height: 100vh;
    /*background: linear-gradient(180deg, #35E0F4 0.45%, #33D7F0 7.35%, #2EBDE4 18.82%, #2594D0 33.45%, #394da1 50.5%, #323f98 61.53%, #252385 99.55%);*/
}

.dashboard-email-item {
    padding: 20px;
    /*margin: 19px 28px;*/
    height: 80px;
    background: #FBFBFB;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 7px;
    /*cursor: pointer;*/
    width: 100%;
    border: 1px solid #D1D1D1;
}

.dashboard-email-text {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    color: #15359D;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dashboard-content-item {
    padding: 15px;
    height: 80px;
    background: #FBFBFB;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 7px;
    width: 100%;
    border: 1px solid #D1D1D1;
}

.accordion-list-item {
    padding: 15px;
    /* height: 80px; */
    /* background: #FBFBFB; */
    /* box-shadow: 0 2px 5px rgb(0 0 0 / 30%); */
    border-radius: 7px;
    /* width: 100%; */
    border: 1px solid #D1D1D1;
    margin-top: 10px;
}

.dashboard-add-btn {
    background: #16359D !important;
    border-radius: 100px !important;
    color: white !important;
    font-weight: bold !important;
    padding: 5px 5px !important;
    margin-top: -20px !important;
}

.sell-send-wallet-address {
    font-size: 24px;
    text-align: left;
    padding: 10px 25px 0px 15px;
    border: 1px solid #1335A3;
    border-radius: 10px;
    overflow-wrap: anywhere;
}

.sell-send-copy {
    float: right;
    position: relative;
    bottom: 43px;
    left: 30px;
}

.sell-send-icon {
    float: left !important;
    margin-top: -10px !important;;
    margin-right: 10px !important;;
}

.history-item {
    padding: 20px;
    margin: 19px 0;
    background: #FBFBFB;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    border-radius: 7px;
    cursor: pointer;
}

.text-overflow-cut {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.history-status-failed, .history-status-not_approved {
    color: #F44336;
}

.history-status-complete {
    color: #00B171;
}

.post-twitter-btn {
    border: 0 none;
    border-radius: 100px;
    padding: 5px 10px;
    background: #16359D;
    color: white;
    height: 35px;
    cursor: pointer;
}

.aff-btn {
    margin-left: 5px !important;
    background: green !important;
}

.twitter-btn-div {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.twitter-button {
    display: inline-block;
    border-radius: 5px;
    border: 1px solid #cccccc;
    padding: 8px;
    background-image: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.1) 100%);
    font-weight: bold;
    margin-top: 32px;
    color: #333333;
    text-decoration: none;
}

.tweet-text-parent {
    padding: 2px;
    border:1px solid #ccc;
    min-width: 300px;
    max-width: 450px;
    max-height:250px;
    min-height: 200px;
    overflow:auto;
    outline: none;
    resize: both;
    background: white;
}

.tweet-disable-text {
    background-color:rgb(232, 232, 232);
    width: fit-content;
    padding-left:10px;
    padding-right:10px;
    border-radius:10px;
    height: fit-content;
}

.tweet-text-area {
    width: 100%;
    border: 0px solid;
    outline: none;
    resize: none;
    font-size: 16px;
    height: 100px;
}

.input-label-feature {
    text-align: left;
    /* color: #000000; */
    font-weight: bold !important;
}

.trade-balance-caption {
    text-align: right;
    font-weight: bold !important;
    margin-top: 16px !important;
}

.max-input-btn {
    border-radius: 100px;
    background: var(--Box-BG, linear-gradient(134deg, rgba(255, 255, 255, 0.68) -22.35%, rgba(245, 245, 245, 0.74) 77.69%, rgba(188, 188, 188, 0.80) 109.17%));
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20);
    color: black !important;
    font-weight: bold !important;
    padding: 4px !important;
    margin-top: -20px !important;
    font-size: 9px !important;
    position: relative;
    top: -2px;
    cursor: pointer;
}

.balance-header {
    background: linear-gradient(270deg, #1132A3 0%, #35DFF4 100%);
    padding: 10px;
    color: white;
    font-size: 18px;
}

.balance-token-symbol {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
}

.balance-token-cell {
    text-align: left;
    display: flex;
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.send-info {
    border: 2px solid #16359D;
}

.send-summary-grid {
    padding: 20px;
}

.send-summary-divider {
    border-bottom-width: 2px !important;
    background-color: #16359D;
}

.send-wallet-address {
    overflow: hidden;
    text-overflow: ellipsis;
}

.exch-gift-icon {
  margin-top: -7px;
  margin-right: 5px;
}

.exch-gift-clr {
    color: #2ec2e6;
}

.reward-footer {
    padding-left: 55px;
    margin-top: 150px;
}

.gift-popup-reward {
    font-size: 20px !important;
    font-weight: bold !important;
    color: #16359D !important;
}

.gift-popup-text {
    font-size: 18px;
}

.gift-popup-icon {
    margin-right: 5px;
    margin-bottom: -2px;
}

.gift-cell {
    padding-top: 5px !important;
}

.gift-text-desc {
    font-weight: 500 !important;
}

.gift-btn-width {
    width: 60% !important;
}

.ref-reward {
    color: #1233A3 !important;
}

.ref-reward-step {
    color: #C7C7C7 !important;
    margin-left: 10px;
}

.select-network-label {
    font-size: 13px !important;
    font-weight: bold !important;
    color: white !important;
    padding: 4px !important;
    border-radius: 5px !important;
    top: 45px !important;
    left: 50px !important;
    text-align: center;
    width: 100px;
    text-transform: uppercase;
}

.eth-network {
    background: #7985cd;
    opacity: 0.7;
    
}
.everchain-network {
    background: #195BB6;
    opacity: 0.7;
}

.crypto-picker span {
    bottom: 18px !important;
}

.fiat-picker span {
    bottom: 12px !important;
}

.modal-token-image {
    width: 40px;
}

.modal-token-network {
    border-radius: 6px;
    text-transform: uppercase;
    color: white;
    font-size: 10.5px;
    font-weight: 700;
    padding: 6px 4px 4px 4px;
    width: 80px !important;
    display: flex;
    justify-content: center;
}

.confirm-transaction-network {
    border-radius: 6px;
    text-transform: uppercase;
    color: white;
    font-size: 9px;
    font-weight: 700;
    width: 65px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-network-wrapper {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
}

.swap-fiat-values {
    font-weight: bold !important;
    /* margin-top: -25px !important; */
    font-size: 16px !important;
    color: #A6A6A6 !important;
    position: absolute;
    bottom: 5px;
    left: -2px;
}

.sc-KLwla {
    background: white !important;
    z-index: 100;
}

.confirm-payment {
    border-radius: 4px;
    border: 2px solid #16359D; 
    width: 150px;
    padding: 15px 25px;
    text-align: center;
}

.deposit-wallet-address {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px !important;
    background: white !important;
    border-radius: 4px;
    width: 100%;
    text-align: center;
}

.deposit-wallet-address p {
    font-size: 26px !important;
    font-weight: bold !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.confirm-address {
    border-radius: 4px;
    border: 2px solid; 
    width: 100%;
    padding: 15px 25px;
    text-align: center;
}

.confirm-address p {
    font-size: 26px !important;
    font-weight: bold !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rotateIcon {
    animation: spin 1s linear infinite
}

.terms-modal-btn {
    color: #A6A6A6;
    font-weight: bold !important;
    cursor: pointer;
}

.terms-modal-btn .active {
    color: #16359D !important;
}

.wallet-caption-text {
    text-align: center;
    color: #A1A1A1;
}

.wallet-select-form-control .MuiSelect-filled, .wallet-select-form-control label, .wallet-select-form-control svg {
    font-weight: 700;
    color: black;
}

.wallet-select-form-control label {
    left: 23%;
}

.vertical-align-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 200px);
}

.withdraw-receive-symbol {
    font-weight: bold !important;
    font-size: 24px !important;
    margin-top: 10px !important;
}

.swap-progress-modal {
    padding: 40px 80px !important;
    /* height: 600px; */
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg)
    }
}

@media screen and (max-width: 800px) {
    .incode-mobile-view .sc-fwQFQH {
        width: 195% !important;
    }
    .incode-mobile-view .sc-KLwla {
        overflow-x: scroll !important;
        overflow-y: hidden !important;
    }
}

@media only screen and (max-width: 450px) {
    .incode-mobile-view .sc-fwQFQH {
        width: 125% !important;
        flex-direction: row-reverse !important;
    }
    .incode-mobile-view .sc-bbBEzO:first-child, .incode-mobile-view .sc-iSATDh {
        display: none !important;
    }
}

@media only screen and (max-width: 600px) {
    .token-image-grid {
        padding-left: 0px !important;
    }

    .modal-token-image {
        width: 40px;
    }

    .modal-token-network {
        font-size: 10px;
    }
    .swap-progress-modal {
        padding: 40px 10px !important;
    }
}

@media only screen and (min-width: 992px) {
    /* Desktop */
}